var appId = 'ce5da9fbe0f74115bc58eb1acc20bcfa';
var redirectUri = 'https://metroticket.tongyindigitalqpay.com/dtb/unionPayMiniApp/contractApply'; // 签约重定向的
var responseType = 'code';
var scope = 'upapi_contract';
var planId = '59992c9e474044638c9d6bb92aeebc9b';
// var repayment = 'https://metroticket.tongyindigitalqpay.com/#/home'; // 次票小程序项目地址
var repayment = 'https://pageticket.tongyindigitalqpay.com/?'; // 次票小程序项目地址,CDN地址
var PwdHtml = 'https://open.95516.com/s/open/noPwd/html/open.html?'; //无感支付签约地址

var baseUrl = 'https://metroticket.tongyindigitalqpay.com';
var financeBaseUrl='https://metroticket.tongyindigitalqpay.com'
var newsUrl = 'https://pageticket.tongyindigitalqpay.com';
// 模拟闸机开关，打开后不校验31报文mac
var mockZhaji = false;
var locationShow=false //手动输入坐标弹窗  生产不显示
var version = 470
var appletId = '066ea7c2a52c1d00';
var host = 'https://metroticket.tongyindigitalqpay.com/track/';
var trackerScriptUrl = 'https://pageticket.tongyindigitalqpay.com/resources/matomo.js';
var trackerFileName = 'matomo';
var matomoOpen = true;
var ysfMarketUrlPattern = 'https://chuxinget.fpsd.unionpay.com/traffic-marketing/html/merchantList.html?appId=[appId]&openId=[openId]&opId=C310020180200001&serviceId=01&stationNo=[stationNo]&stationNm=[stationNm]&lineNo=[lineNo]&lineNm=[lineNm]&direction=01&cityId=3100';
var NODE_ENV='prod'
var CHANNEL='YUNSHANFU';
export {
  appId,
  redirectUri,
  responseType,
  scope,
  planId,
  repayment,
  PwdHtml,
  baseUrl,
  financeBaseUrl,
  newsUrl,
  mockZhaji,
  version,
  appletId,
  host,
  trackerScriptUrl,
  trackerFileName,
  matomoOpen,
  ysfMarketUrlPattern,
  locationShow,
  NODE_ENV,
  CHANNEL,
};
